/* eslint-disable */
function arrayShuffleInPlace( array )
{
    for( let i = array.length; i; i-- ){
        let j = Math.floor( Math.random() * i );
        [array [i - 1], array [j]] = [array [j], array [i - 1]];
    }
};

export default {
    methods: {
        arrayShuffleInPlace
    }
};
