<template>
    <div :class="{ 'main-layout': true, [ ( $route.name ? $route.name.toLowerCase() : '' ) + '-route' ]: true }">
        <div class="white-layer"></div>

        <img class="stl-label" src="/static/img/stl-label.png" />

        <div class="content">
            <slot></slot>
        </div>
        <footer>
            <!--div class="footer-prefix">
                <div class="orange">
                    In 2019 kan het gebeuren...
                </div>
            </div-->
            <div class="content-wrapper">
                <img src="/static/img/logos/footer.png" />
                <p class="disclaimer">Voor het organiseren van Staatsloterij is aan Staatsloterij bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk.<br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a :href="'/static/legal/actievoorwaarden.pdf'" target="_blank">actievoorwaarden</a>. | <a href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse Loterij</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
    .white-layer{
        position:fixed;
        top:0;left:0;right:0;bottom:0;
        background-color:white;
        opacity:0;
        transition:opacity 0.3s ease-out;
    }

    .main-layout.test-route, .main-layout.naw-route, .main-layout.result-route{
        .white-layer{
            opacity:0.75;
        }
    }
</style>

<script>
    export default {

    };
</script>
