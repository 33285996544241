import Vue from 'vue';
import Vuex from 'vuex';

import EntryProxy from '@/app/proxies/EntryProxy';
import quiz from './modules/quiz';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        quiz
    },

    state: {
        savedEntry: null,
        firstname: null
    },

    getters: {
        getEntry: state => state.savedEntry,
        getFirstname: state => state.firstname
    },

    mutations: {
        savedEntry: ( state, payload ) =>
        {
            state.savedEntry = payload;
        },

        saveFirstname: ( state, payload ) =>
        {
            state.firstname = payload;
        }
    },

    actions: {
        saveEntry( context, payload )
        {
            context.commit( 'saveFirstname', payload.firstname );

            return new EntryProxy()
                .create( payload )
                .then( ( response ) =>
                {
                    context.commit( 'savedEntry', response );
                    return response;
                })
                .catch( ( error ) =>
                {
                    return Promise.reject( error );
                });
        },
        saveAnswer: ( context, answer ) =>
        {
            return context.dispatch( 'quiz/answerGiven', answer );
        },
        newQuiz: ( context ) =>
        {
            context.dispatch( 'quiz/newQuiz' );
            // context.dispatch( 'player/reset' ); // reset scores?
        },
        nextQuestion: ( context, answer ) =>
        {
            return context.dispatch( 'quiz/nextQuestion' );
        }
    }
});
