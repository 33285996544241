export default {
    attributes: {
        gender: 'Aanhef',
        firstname: 'Voornaam',
        infixname: 'Tussenv.',
        surname: 'Achternaam',
        zipcode: 'Postcode',
        house_num: 'Huisnr.',
        house_num_add: 'Toev.',
        telephone: 'Telefoonnummer',
        emailaddress: 'E-mailadres',
        birthday: 'Geboortedatum (DD/MM/JJJJ)'
    },
    custom: {
        age: {
            required: 'Deze checkbox is verplicht.'
        }
    }
};
