import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import VuexRouterSync from 'vuex-router-sync';
import store from '@/app/store';
import Axios from 'axios';
import VeeValidate from 'vee-validate';
import dict from 'vee-validate/dist/locale/nl';
import attr from '@/app/i18n';

Vue.use( VeeValidate, {
    events: 'blur'
});

VeeValidate.Validator.extend( 'date_valid', {
    getMessage: field => 'The ' + field + ' value is not truthy.',
    validate: value =>
    {
        let parts = value.split( '-' );
        let day = parseInt( parts[ 2 ] );
        let month = parseInt( parts[ 1 ] - 1 );
        let year = parseInt( parts[ 0 ] );
        let date = new Date( year, month, day, 0, 0, 0 );

        return day === date.getDate() && month === date.getMonth() && year === date.getFullYear();
    }
});

VeeValidate.Validator.localize( 'nl', Object.assign( dict, attr.nl ) );

VuexRouterSync.sync( store, router );
Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

Vue.config.productionTip = false;

store.dispatch( 'newQuiz' );

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
