let questions = require( '@/app/data/questions.json' );

export default {
    namespaced: true,

    state: {
        index: 0,
        showResult: false,
        sequence: [],
        answers: [],
        questions
    },

    getters: {
        currentQuestion: state =>
        {
            let is = state.sequence[ state.index ];
            return is ? state[ is.type ][ is.id ] : null;
        },
        currentQuestionNumber: state =>
        {
            return state.index;
        },
        answers: state =>
        {
            return state.answers;
        },
        showResult: state =>
        {
            return state.showResult;
        },
        testResult: state =>
        {
            let profiles = {};
            for( let i in state.answers )
            {
                for( let key in state.answers[i].category )
                {
                    if( !profiles[ key ] )
                    {
                        profiles[ key ] = 0;
                    }

                    profiles[key] += state.answers[i].category[ key ];
                }
            }

            let sorted = Object.keys( profiles ).sort( ( a, b ) => { return profiles[ b ] - profiles[ a ]; });

            return sorted[0] || 'kampeerder';
            /*
            sportieveling
            jetsetter
            zonaanbidder
            watersporter
            avonturier
            kampeerder */
        }
    },

    mutations: {
        showResult: ( state, show ) =>
        {
            state.showResult = show;
        },
        addAnswer: ( state, answer ) =>
        {
            state.answers.push( answer );
        },

        newQuiz: ( state ) =>
        {
            let sequence = [];
            // let questions = shuffle( Object.keys( state.questions ) );
            let questions = Object.keys( state.questions );

            let total = questions.length;
            for( let i = 0; i < total; i++ )
            {
                let question = questions.pop();
                sequence.push({ type: 'questions', id: question });
            }

            state.sequence = sequence.reverse();
            // state.sequence = sequence;
            state.answers = [];
            state.index = 0;
        },
        nextQuestion: ( state ) =>
        {
            if( state.index < state.sequence.length - 1 )
            {
                state.index++;
            }
        }
    },

    actions: {
        showResult: ( context, show ) =>
        {
            context.commit( 'showResult', show );
        },
        newQuiz: ( context ) =>
        {
            context.commit( 'newQuiz' );
        },

        answerGiven: ( context, answer ) =>
        {
            context.commit( 'addAnswer', answer );

            if( context.state.index < context.state.sequence.length - 1 )
            {
                return Promise.resolve({ more: true });
            }
            return Promise.resolve({ more: false });
        },

        nextQuestion: ( context ) =>
        {
            context.commit( 'nextQuestion' );
        }
    }
};
