import Vue from 'vue';
import Router from 'vue-router';
import { TweenMax } from 'gsap';
/* eslint-disable-next-line */
import ScrollToPlugin from 'gsap/ScrollToPlugin';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/resultaat',
            name: 'Result',
            meta: {
                pageType: 'promotion-confirmation'
            },
            component: () =>
            {
                return import( /* webpackChunkName: "result" */ '@/app/pages/Result' );
            }
        },
        {
            path: '/gegevens',
            name: 'Naw',
            meta: {
                pageType: 'promotion-leadform'
            },
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            }
        },
        {
            path: '/test/:number',
            name: 'Test',
            meta: {
                pageType: 'promotion-content'
            },
            component: () =>
            {
                return import( /* webpackChunkName: "test" */ '@/app/pages/Test' );
            }
        },
        {
            path: '/',
            name: 'Landing',
            meta: {
                pageType: 'promotion-landing'
            },
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },

        {
            path: '/share/:id',
            name: 'ShareRedirect',
            beforeEnter: ( to, from, next ) =>
            {
                // console.log( 'here' );
                next( '/' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    dataLayer.push({
        event: 'pageview',
        origin: 'website',
        type: to.meta.pageType,
        virtualPage: to.fullPath
    });

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    TweenMax.to( 'html,body', 0.3, { scrollTop: 0 });

    next();
});

export default appRouter;
